<template>
  <div>
    <div class="mass-action-settings">

      <el-select
        v-model="actionData.section_type"
        size="small"
        style="width: 500px"
        placeholder="Выберите блок"
        @change="sectionChanged"
      >
        <el-option
          v-for="(sectionConfig, sectionType) in $companyConfiguration.cardSections.getSections()"
          v-if="sectionConfig.fromLibrary"
          :key="sectionType"
          :label="sectionConfig.showName"
          :value="sectionType"
        >
        </el-option>
      </el-select>

      <div
        v-if="actionData.section_type"
        style="margin-top: 10px"
      >
        Выбранные КПЭ:
        <div v-for="element in library_elements" style="margin-bottom: 5px">
          <el-button
            style="padding: 0"
            type="text"
            icon="fas fa-trash"
            @click="deleteKPI(element)"
          ></el-button>
          {{element.name}}
        </div>

        <el-button
          style="margin-top: 10px"
          size="mini"
          icon="fas fa-plus"
          @click="addElementFromLibrary"
        >
          {{ $companyConfiguration.i18n.t('card', 'section_add_element_button') }}
        </el-button>

      </div>


      <el-popconfirm
        style="margin-top: 25px; display: block"
        confirm-button-text='Да'
        cancel-button-text='нет'
        icon="el-icon-question"
        title="Вы уверены, что хотите добавить КПЭ?"
        @confirm="doAction"
      >
        <el-button
          slot="reference"
          size="small"
          type="primary"
          :disabled="actionButtonDisabled"
        >
          Добавить
        </el-button>

      </el-popconfirm>

    </div>

    <mass-action-result
      v-if="result"
      :result="result"
      :error-props="['id', 'info.last_name', 'info.first_name', 'info.middle_name', 'info.company']"
    ></mass-action-result>

    <element-add-from-library-dialog
      ref="addElementFromLibraryDialog"
      @elements-selected="elementsSelected"
    >
    </element-add-from-library-dialog>
  </div>
</template>

<script>
import {ActionTemplateMixin} from "@/components/massActions/actionTemplates/ActionTemplateMixin";
import CardsStageSelect from "@/components/filters/cards/CardsStageSelect.vue";
import MassActionResult from "@/components/massActions/MassActionResult.vue";
import CardsStageNegotiationSelect from "@/components/filters/cards/CardsStageNegotiationSelect.vue";
import requestSender from "@/api/base/requestSender";
import ElementAddFromLibraryDialog from "@/components/cards/CardElementsBlock/ElementAddFromLibraryDialog.vue";

export default {
  name: "card-add-element-from-library-action",
  mixins: [ActionTemplateMixin],
  components: {ElementAddFromLibraryDialog, CardsStageNegotiationSelect, MassActionResult, CardsStageSelect},

  computed: {
    actionButtonDisabled() {
      return !this.library_elements.length;
    },
  },
  watch: {
    library_elements() {
      this.actionData.library_element_ids = this.library_elements.map(element => element.id);
    }
  },

  data() {
    return {
      library_elements: [],
      actionData: {
        section_type: null,
        library_element_ids: null,
      }
    }
  },
  methods: {
    sectionChanged(){
      this.library_elements = [];
    },

    addElementFromLibrary() {
      const libraryType = this.$companyConfiguration.cardSections.getSectionConfig(this.actionData.section_type)['fromLibrary'];
      this.$refs.addElementFromLibraryDialog.open(libraryType);
    },

    elementsSelected(elements){
      this.$refs.addElementFromLibraryDialog.close();
      this.library_elements = [...this.library_elements, ...elements];
    },

    deleteKPI(element){
      this.library_elements = this.library_elements.filter(e=>e!==element);
    },
  },
}
</script>
